import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Checkbox, Slider } from '@mui/material';
import { NumberOfQuestions } from '../../controls';
import {
  PositionsByCategoryModel,
  QuestionAnswerRateModel,
  SelectedExamCategoriesModel,
} from '../../../models';
import { MAX_NUMBER_OF_QUESTIONS_FOR_EXAM } from '../../pages/QToolCreateCustomExam/parts/CreateExamStep2';
import { collectEstimatedTime } from '../../../helpers/commonHelpers';
import { ReactComponent as InfoIcon } from '../../../assets/images/icons/notification_ic.svg';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

export enum WorkOnMistakesTypesEnum {
  fixed_amount = 'fixed_amount',
  threshold = 'threshold',
}

export type WorkOnMistakesType = Record<WorkOnMistakesTypesEnum, boolean>;

const DEFAULT_THRESHOLD_VALUE = 25;
const MISSED_QUESTIONS_TOOLTIP_TEXT =
  'The count of incorrectly answered questions for this pool. ' +
  'This opportunity allows you to improve your knowledge of ' +
  'the questions you have previously missed.';
const THRESHOLD_TOOLTIP_TEXT =
  'Difficulty by threshold creates exams based on your previous success or failure of ' +
  'questions. The lower the percentage the fewer questions in the pool but the ' +
  'more difficult they will be. This option is most useful for students who have ' +
  'already completed many Extra Credit questions multiple times.';

interface IProps {
  categories: PositionsByCategoryModel[];
  selectedCategories: SelectedExamCategoriesModel[];
  isActive: boolean;
  onCollectStep2ForAdvancedExam: (
    fixedAmount: number,
    threshold: number,
    thresholdNumberOfQuestions: number,
  ) => void;
  courseId: string | undefined;
  questions: QuestionAnswerRateModel[] | null;
  selectedWorkOnMistakesType: WorkOnMistakesType;
  setSelectedWorkOnMistakesType: (type: WorkOnMistakesType) => void;
  thresholdNumberOfQuestions: number;
  setThresholdNumberOfQuestions: (questionsNumber: number) => void;
}

const WorkOnMistakesExam = ({
  categories,
  selectedCategories,
  isActive,
  courseId,
  onCollectStep2ForAdvancedExam,
  questions,
  selectedWorkOnMistakesType,
  setSelectedWorkOnMistakesType,
  thresholdNumberOfQuestions,
  setThresholdNumberOfQuestions,
}: IProps) => {
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const [numberOfPossibleQuestions, setNumberOfPossibleQuestions] = useState<number>(0);
  const [thresholdRange, setThresholdRange] = useState<number>(DEFAULT_THRESHOLD_VALUE);
  const [numberOfDifficultQuestions, setNumberOfDifficultQuestions] = useState<number>(
    numberOfPossibleQuestions,
  );
  const [numberOfSelectedQuestions, setNumberOfSelectedQuestions] = useState<number>(
    numberOfDifficultQuestions,
  );
  const [
    questionsNumberCorrespondingOneHundredThreshold,
    setQuestionsNumberCorrespondingOneHundredThreshold,
  ] = useState<number>(numberOfDifficultQuestions);

  useEffect(() => {
    const thresholdQuestions =
      questions?.filter(
        entry => entry.percentage_incorrect_answers >= 100 - thresholdRange,
      ).length || 0;
    const validQuestionsCount = Math.min(
      thresholdQuestions,
      MAX_NUMBER_OF_QUESTIONS_FOR_EXAM,
    );
    setThresholdNumberOfQuestions(validQuestionsCount);
    setNumberOfSelectedQuestions(validQuestionsCount);
  }, [thresholdRange, questions, selectedWorkOnMistakesType.threshold]);

  useEffect(() => {
    if (!isActive) return;
    if (selectedWorkOnMistakesType[WorkOnMistakesTypesEnum.fixed_amount]) {
      onCollectStep2ForAdvancedExam(
        numberOfDifficultQuestions,
        0,
        thresholdNumberOfQuestions,
      );
    } else {
      onCollectStep2ForAdvancedExam(0, thresholdRange, thresholdNumberOfQuestions);
    }
  }, [selectedWorkOnMistakesType, thresholdRange, numberOfDifficultQuestions, isActive]);

  useEffect(() => {
    // if (selectedWorkOnMistakesType[WorkOnMistakesTypesEnum.threshold]) {
    //   setNumberOfSelectedQuestions(thresholdNumberOfQuestions);
    // }
    if (selectedWorkOnMistakesType[WorkOnMistakesTypesEnum.fixed_amount]) {
      setNumberOfSelectedQuestions(numberOfDifficultQuestions);
    }
  }, [selectedWorkOnMistakesType.threshold, selectedWorkOnMistakesType.fixed_amount]);

  useEffect(() => {
    if (!questions) return;
    const maxPossibleNumberOfQuestions =
      questions.length >= MAX_NUMBER_OF_QUESTIONS_FOR_EXAM
        ? MAX_NUMBER_OF_QUESTIONS_FOR_EXAM
        : questions.length;
    const questionsCorrespondingDefaultThreshold = questions.filter(
      question => question.percentage_incorrect_answers >= DEFAULT_THRESHOLD_VALUE,
    );
    const questionsCorrespondingOneHundredThreshold = questions.filter(
      question => question.percentage_incorrect_answers >= 100,
    );
    setQuestionsNumberCorrespondingOneHundredThreshold(
      questionsCorrespondingOneHundredThreshold.length,
    );
    const maxPossibleThresholdQuestions =
      questionsCorrespondingDefaultThreshold.length >= MAX_NUMBER_OF_QUESTIONS_FOR_EXAM
        ? MAX_NUMBER_OF_QUESTIONS_FOR_EXAM
        : questions.length;
    setTotalQuestions(questions.length);
    setThresholdNumberOfQuestions(maxPossibleThresholdQuestions);
    setNumberOfPossibleQuestions(maxPossibleNumberOfQuestions);
    setNumberOfDifficultQuestions(maxPossibleNumberOfQuestions);
  }, [questions]);

  const handleSelectType = (type: WorkOnMistakesTypesEnum) => {
    if (!isActive) return;
    if (type === WorkOnMistakesTypesEnum.fixed_amount) {
      setSelectedWorkOnMistakesType({
        [WorkOnMistakesTypesEnum.fixed_amount]: true,
        [WorkOnMistakesTypesEnum.threshold]: false,
      });
    } else {
      setSelectedWorkOnMistakesType({
        [WorkOnMistakesTypesEnum.fixed_amount]: false,
        [WorkOnMistakesTypesEnum.threshold]: true,
      });
    }
  };

  const handleThresholdChange = (
    event: Event,
    value: number | number[],
    activeThumb: number,
  ) => {
    const newValue = value as number;
    setThresholdRange(newValue);
  };

  const totalEstimate = collectEstimatedTime(numberOfDifficultQuestions);

  const handleNumberQuestion = (val: string) => {
    const numVal = Number(val);

    const setQuestionCount = selectedWorkOnMistakesType[WorkOnMistakesTypesEnum.threshold]
      ? setThresholdNumberOfQuestions
      : setNumberOfDifficultQuestions;

    if (numVal >= MAX_NUMBER_OF_QUESTIONS_FOR_EXAM) {
      setQuestionCount(MAX_NUMBER_OF_QUESTIONS_FOR_EXAM);
      return;
    }

    if (numVal >= numberOfPossibleQuestions) {
      setQuestionCount(numberOfPossibleQuestions);
      return;
    }
    setNumberOfSelectedQuestions(numVal);
    setQuestionCount(numVal);
  };

  const isThresholdHintVisible = (): boolean => {
    return (
      isActive &&
      selectedWorkOnMistakesType[WorkOnMistakesTypesEnum.threshold] &&
      questionsNumberCorrespondingOneHundredThreshold >=
        Math.min(MAX_NUMBER_OF_QUESTIONS_FOR_EXAM, totalQuestions)
    );
  };

  const renderTitleWithTypography = (title: string, type: WorkOnMistakesTypesEnum) => {
    return (
      <div className="d-flex align-items-center">
        <CustomTooltip title={MISSED_QUESTIONS_TOOLTIP_TEXT} isWide={true}>
          <InfoIcon className="notification-icon mr-2" />
        </CustomTooltip>
        <div className="question-tile flex-grow-1" onClick={() => handleSelectType(type)}>
          <Checkbox
            disabled={!isActive}
            sx={{
              color: 'var(--text-color)',
            }}
            checked={selectedWorkOnMistakesType[type]}
          />
          <span>{title}</span>
        </div>
      </div>
    );
  };

  const renderTitleWithSlider = (title: string, type: WorkOnMistakesTypesEnum) => {
    return (
      <div>
        <div className="d-flex align-items-center">
          <CustomTooltip title={THRESHOLD_TOOLTIP_TEXT} isWide={true}>
            <InfoIcon className="notification-icon mr-2" />
          </CustomTooltip>
          <div
            className="question-tile flex-grow-1"
            onClick={() => handleSelectType(type)}
          >
            <Checkbox
              sx={{
                color: 'var(--text-color)',
              }}
              checked={selectedWorkOnMistakesType[type]}
            />
            <span className="thresholdTitle">{title}</span>
            <Slider
              disabled={
                !isActive ||
                selectedWorkOnMistakesType[WorkOnMistakesTypesEnum.fixed_amount]
              }
              size="small"
              value={thresholdRange}
              onChange={handleThresholdChange}
              aria-label="Small"
              valueLabelDisplay="off"
              className="thresholdSlider"
              step={1}
              sx={{
                color: '#32AB78',
              }}
            />
            <div className="thresholdRange">{thresholdRange} %</div>
          </div>
        </div>
        {isThresholdHintVisible() && (
          <p style={{ marginLeft: '28px', marginTop: '12px' }}>
            All available questions meet the threshold condition.
          </p>
        )}
      </div>
    );
  };

  return (
    <div className={`question-types-wrapper ${!isActive ? '-not-active' : ''}`}>
      <Typography variant="h6" fontWeight={600} align={'center'}>
        Focus on Corrections
      </Typography>
      <div className="question-types">
        {renderTitleWithTypography(
          'Number of missed/difficult questions',
          WorkOnMistakesTypesEnum.fixed_amount,
        )}
        {renderTitleWithSlider(
          'Difficulty by threshold',
          WorkOnMistakesTypesEnum.threshold,
        )}
      </div>
      <NumberOfQuestions
        handleChangeOfQuestionsNumber={handleNumberQuestion}
        initialNumberOfQuestions={numberOfDifficultQuestions}
        currentNumberOfQuestions={numberOfSelectedQuestions}
        totalEstimate={totalEstimate}
        numberOfPossibleQuestions={numberOfPossibleQuestions}
        totalQuestions={totalQuestions}
        isDisabled={!isActive}
        isReadOnly={selectedWorkOnMistakesType[WorkOnMistakesTypesEnum.threshold]}
      />
    </div>
  );
};

export default WorkOnMistakesExam;
