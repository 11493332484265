import DOMPurify from 'dompurify';
import { AssignmentModel } from '../models';
import { QUESTION_ESTIMATE } from '../constants/resource';

export const cutString = (str: string, length: number) => {
  if (str.length <= length) {
    return str;
  }
  return `${str.slice(0, length)}...`;
};

export const isEmptyObject = (obj: object | null | undefined) => {
  if (!obj) return true;
  return !Object.values(obj).length;
};

export const filterBySectionName = (arr: AssignmentModel[]) => {
  let arrBySectionName: any[] = [];
  for (let idx = 0; idx < arr.length; idx++) {
    if (arr[idx].section) {
      let sectionId = arrBySectionName
        .map(object => object.sectionName?.id)
        .indexOf(arr[idx].section?.id);
      if (sectionId !== -1) {
        arrBySectionName[sectionId].sectionData.push(arr[idx]);
      } else {
        arrBySectionName.push({ sectionName: arr[idx].section, sectionData: [arr[idx]] });
      }
    } else {
      arrBySectionName.push(arr[idx]);
    }
  }
  return arrBySectionName;
};

export function toHoursAndMinutes(totalMinutes: number) {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function toHoursMinutesAndSeconds(seconds: number) {
  return new Date(seconds * 1000).toISOString().slice(11, 19);
}

export const sanitizedHTML = (html: string | null) => {
  return DOMPurify.sanitize(html || '');
};

export const isEmptyField = (text = '') => {
  if (text && text !== '<p><br></p>') {
    return false;
  }
  return true;
};

export function removeDuplicates(arr: any[]) {
  return arr.reduce(function (acc, curr) {
    if (!acc.includes(curr)) acc.push(curr);
    return acc;
  }, []);
}

export function getMacVersion() {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isMac = /Macintosh/.test(userAgent);
  if (isMac) {
    const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
    if (version) {
      return version && version[1] ? version[1] : '';
    }
  }
}

export function getIOSVersion() {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const iOSDevice =
    /iPad|iPhone|iPod/.test(userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  if (iOSDevice) {
    const version = navigator.userAgent.match(/Version\/(\d+)\.(\d+)\.?(\d+)?/);
    if (version) {
      return version && version[1] ? version[1] : '';
    }
    const match = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (match) {
      return Number(match[1]);
    }
  }
  return null;
}

export function formatDate(isoDate: string) {
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  const formattedHours = String(hours).padStart(2, '0');
  return `${month}/${day}/${year}  ${formattedHours}:${minutes} ${ampm}`;
}

export const collectEstimatedTime = (questionCount: number) => {
  const totalSeconds = questionCount * QUESTION_ESTIMATE;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours ? `${hours}h ` : ''}${minutes}m ${seconds ? `${seconds}s` : ''}`;
};

export const collectTakenTime = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  if (hours === 0 && minutes === 0 && seconds === 0) {
    return '0s';
  }
  return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''} ${
    seconds ? `${seconds}s` : ''
  }`;
};

export const logEvent = (eventName: string, eventParams: any) => {
  // @ts-ignore
  if (window.gtag) {
    // @ts-ignore
    window.gtag('event', eventName, eventParams);
  } else {
    console.warn('gtag is not defined');
  }
};

export const getFormattedDateToUS = (date_iso: string) => {
  const date = new Date(date_iso);
  if (Number.isNaN(date.getTime())) return '';

  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
export const formattedDate = new Date().toLocaleDateString('en-US', options);

export function shuffleArray(array: any[]) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }

  return shuffled;
}

export const decodeHtml = (html: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText;
};
